export const RECAPTCHA_KEY = '6LdL_rMZAAAAAJZjyibWPyOcxYKkhwjRoTPUJDSa'


const serverUrls = {
  'localhost': 'http://be-biztrips.wenus.softnauts.com',
  'biztrips-landing.wenus.softnauts.com': 'http://be-biztrips.wenus.softnauts.com',
  'www.biztrips-landing.wenus.softnauts.com': 'http://be-biztrips.wenus.softnauts.com',
  'biztrips.app': 'https://api.biztrips.app',
  'www.biztrips.app': 'https://api.biztrips.app',
  'biztrips.ch': 'https://api.biztrips.ch',
  'www.biztrips.ch': 'https://api.biztrips.ch',
}


export const serverURL = serverUrls[window.location.hostname]