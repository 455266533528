import React, { useState } from 'react'
import './LoginBox.scss'
import { withRouter } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { default as axios } from '../../axiosSettings'
import { validateEmail, validatePassword } from '../../utils'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'


function LoginBox(props) {
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [codeError, setCodeError] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [boxScreen, setBoxScreen] = useState('enterCredentials') // enterCredentials, chooseInstance, enterEmail, enterCode, enterNewPassword 
  const [errorMessage, setErrorMessage] = useState(null)
  const [multipleInstancesData, setMultipleInstancesData] = useState([])
  const location = props.location.pathname.toString()
  const [recaptchaToken, setRecaptchaToken] = useState(false)
  const { t, i18n } = useTranslation()


  const handleLogin = () => {
    axios.post(`api/login`, {
      email: login,
      password: password,
    })
      .then(response => {
        const instances = response.data
        if (instances.length === 0) {
          setErrorMessage(t('loginFailedText'))
        }
        else if (instances.length === 1) {
          loginToInstance(response.data[0])
        }
        else if (instances.length >= 2) {
          setMultipleInstancesData(instances)
          setBoxScreen('chooseInstance')
        }
      })
  }


  const loginToInstance = (instanceData) => {
    const tokenExpirationDate = new Date()
    tokenExpirationDate.setHours(tokenExpirationDate.getHours() + 1)
    document.cookie = `token=${instanceData.response.token}; domain=${window.location.hostname}; path=/; expires=${tokenExpirationDate.toUTCString()}`
    document.cookie = `refreshToken=${instanceData.response.refresh_token}; domain=${window.location.hostname}; path=/`
    document.cookie = `userId=${instanceData.response.user.id}; domain=${window.location.hostname}; path=/`
    window.open(instanceData.instanceUrl)
  }


  const handleSendEmail = () => {
    if (validateEmail(email)) {
      setErrorMessage(null)
      axios.post(`api/reset-password`, {
        email: email,
        language: i18n.language,
        tokenCaptcha: recaptchaToken,
      })
        .then(() => {
          setBoxScreen('enterCode')
        })
    } else {
      setErrorMessage(t('emailValidationFailedText'))
    }
  }


  const handleConfirmCode = () => {
    if (code) {
      axios.post(`api/verify-code`, {
        code: code,
      })
        .then(request => {
          if (request.status === 200) {
            setCodeError(false)
            setBoxScreen('enterNewPassword')
          } else {
            setCodeError(true)
          }
        })
        .catch(() => {
          setCodeError(true)
        })
    } else {
      setCodeError(true)
    }
  }


  const handleConfirmPassword = () => {
    if (validatePassword(newPassword)) {
      setErrorMessage(null)
      axios.post(`api/change-password`, {
        code: code,
        newPassword: newPassword,
      })
        .then(request => {
          setBoxScreen('enterCredentials')
        })
    } else {
      setErrorMessage(t('newPasswordValidationFailedText'))
    }
  }


  const loginBoxClasses = props.signUpPage ? 'login-box' : `login-box big-screens ${location !== '/' && 'login-box-hidden'}`

  return (
    <div className={loginBoxClasses}>
      <GoogleReCaptcha onVerify={token => setRecaptchaToken(token)} />

      {
        boxScreen === 'enterCredentials' && <>
          <h3>{t('loginBoxTitle')}</h3>
          <div className="input-wrapper">
            <input className="text-input"
              type="text" 
              placeholder={t('emailInputPlaceholder')} 
              value={login} 
              onChange={e => setLogin(e.target.value)}
              onKeyDown={e => e.key === 'Enter' && handleLogin()} 
            />
          </div>
          <div className="input-wrapper">
            <input className="text-input" 
              type="password" 
              placeholder={t('passwordInputPlaceholder')} 
              value={password} 
              onChange={e => setPassword(e.target.value)}
              onKeyDown={e => e.key === 'Enter' && handleLogin()} 
            />
          </div>
          <button className="button" onClick={handleLogin}>{t('loginButtonText')}</button>
          <div className="forgot-password-link" onClick={() => setBoxScreen('enterEmail')}>{t('forgotPasswordLink')}</div>

          <p className="captcha-statement">
            This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </p>

          { errorMessage && <div className="error-message">{errorMessage}</div> }
        </>
      }

      {
        boxScreen === 'chooseInstance' && <div className="instances-wrapper">
          <p>{t('multipleInstancesText')}</p>
          {
            multipleInstancesData.map((instance, i) => <div key={i} onClick={() => loginToInstance(instance)}>
              {instance.instanceName}
            </div>)
          }
        </div>
      }

      {
        boxScreen === 'enterEmail' && <div>
          <h3>{t('resetPasswordTitle')} </h3>
          <div className="input-wrapper">
            <input className="text-input" 
              type="text" 
              placeholder={t('emailInputPlaceholder')} 
              value={email} 
              onChange={e => setEmail(e.target.value)}
              onKeyDown={e => e.key === 'Enter' && handleSendEmail()} 
            />
          </div>
          <button className="button" onClick={handleSendEmail}>{t('sendInstructionsButton')}</button>
          { errorMessage && <div className="error-message">{errorMessage}</div> }
        </div>
      }

      {
        boxScreen === 'enterCode' && <div>
          <h3>{t('enterCodeTitle')}</h3>
          <div className="subtitle">{t('enterCodeSubtitle')}</div>
          <div className="input-wrapper">
            <input className="text-input" 
              type="text" 
              placeholder={t('codeInputPlaceholder')} 
              value={code} 
              onChange={e => setCode(e.target.value)}
              onKeyDown={e => e.key === 'Enter' && handleConfirmCode()} 
            />
          </div>
          <button className={`button ${codeError && 'validation-failed'}`} onClick={handleConfirmCode}>{codeError ? t('confirmCodeButtonError') : t('confirmCodeButton')}</button>
        </div>
      }

      {
        boxScreen === 'enterNewPassword' && <div>
          <h3>{t('enterNewPasswordTitle')}</h3>
          <div className="subtitle">{t('enterNewPasswordSubtitle')}</div>
          <div className="input-wrapper">
            <input className="text-input" 
              type="password" 
              placeholder={t('newPasswordPlaceholder')} 
              value={newPassword} 
              onChange={e => setNewPassword(e.target.value)}
              onKeyDown={e => e.key === 'Enter' && handleConfirmPassword()} 
            />
          </div>
          <button className="button" onClick={handleConfirmPassword}>{t('confirmPasswordButton')}</button>
          { errorMessage && <div className="error-message">{errorMessage}</div> }
        </div>
      }
      
    </div>
  )
}

export default withRouter(LoginBox)
