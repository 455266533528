import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import ReactGA from 'react-ga'


export const ScrollToTop = () =>{
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (!hash) return
    window.scrollTo(0, 0)
  }, [pathname, hash])

  !hash && setTimeout(() => { // timeout needed to override default back button behavior
    window.scrollTo(0, 0)
  }, 0)

  return null
}


export const validateEmail = email => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const validatePassword = password => {
  // eslint-disable-next-line
  const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})")
  return re.test(String(password))
}

export const getCookie = (name) => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  return match ? match[2] : null;
}


export const initializeGoogleAnalytics = () => {
  const analyticsAllowed = getCookie('acceptedCookies') === 'all'
  if (analyticsAllowed) {
    ReactGA.initialize('UA-171674589-1')
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
}