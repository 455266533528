import React, { useState, useEffect } from 'react'
import './HomePage.scss'
import { Link } from "react-router-dom"
import { validateEmail } from '../../../utils'
import playIcon from '../../../assets/play-icon.svg'
import meditatingDoodle from '../../../assets/meditating-doodle.svg'
import messyDoodle from '../../../assets/messy-doodle.svg'
import trivadisLogo from '../../../assets/trivadis-logo.svg'
import swisscomLogo from '../../../assets/swisscom-logo.svg'
import ruagLogo from '../../../assets/ruag-logo.svg'
import closeIconWhite from '../../../assets/close-icon-white.svg'
import chartIcon from '../../../assets/chart-icon.svg'
import eyeIcon from '../../../assets/eye-icon.svg'
import cloudIcon from '../../../assets/cloud-icon.svg'
import tickIcon from '../../../assets/tick-icon.svg'
import swingingDoodle from '../../../assets/swinging-doodle.svg'
import wavesLeft from '../../../assets/waves-left.svg'
import wavesRight from '../../../assets/waves-right.svg'
import lovingDoodle from '../../../assets/loving-doodle.svg'
import coffeeDoddle from '../../../assets/coffee-doddle.svg'
import biztripsAdvantage1 from '../../../assets/biztrips-advantage-1.gif'
import biztripsAdvantage2 from '../../../assets/biztrips-advantage-2.gif'
import biztripsAdvantage3 from '../../../assets/biztrips-advantage-3.gif'
import biztripsAdvantage4 from '../../../assets/biztrips-advantage-4.gif'
import { useTranslation } from 'react-i18next'
import { default as axios } from '../../../axiosSettings'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Helmet } from "react-helmet"


function HomePage(props) {
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)
  const [activeAdvantagesTile, setActiveAdvantagesTile] = useState(1)
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [message, setMessage] = useState('')
  const [sendButtonText, setSendButtonText] = useState('')
  const [sendButtonClasses, setSendButtonClasses] = useState('button')
  const [contactFormError, setContactFormError] = useState(null)
  const [recaptchaToken, setRecaptchaToken] = useState(false)
  const { t } = useTranslation()


  useEffect(() => {
    setSendButtonText(t('sendButtonText'))
  }, [t])


  useEffect(() => {
    const anchor = props.location.hash
    if (anchor) {
      const domElement = document.querySelector(anchor)
      if (domElement) {
        setTimeout(() => {
          domElement.scrollIntoView()
        }, 300)
      }
    }
  }, [])


  const handleSendMessage = () => {
    const emailError = !validateEmail(email)
    const nameError = !name
    const messageError = !message

    setSendButtonText('Send')
    setSendButtonClasses('button')

    if (!emailError && !nameError && !messageError) {
      axios.post(`api/contact`, {
        email: email,
        name: name,
        text: message,
        tokenCaptcha: recaptchaToken,
      })
        .then(response => {
          if (response.status === 202) {
            setContactFormError(null)
            setSendButtonText(t('sendButtonSuccessText'))
            setSendButtonClasses('button button-green')
          }
          else {
            setSendButtonText(t('sendButtonFailedText'))
            setSendButtonClasses('button button-red')
          }
        })
        .catch(error => {
          setSendButtonText(t('sendButtonFailedText'))
          setSendButtonClasses('button button-red')
        })
    } else {
      setContactFormError(t('contactFormValidationText'))
    }
  }


  return (
    <div className="home-page">
      <GoogleReCaptcha onVerify={token => setRecaptchaToken(token)} />

      <Helmet>
        <title>{t('metaData.homeTitle')}</title>
        <meta name="description" content={t('metaData.homeDescription')} />
      </Helmet>

      <h1>{t('homepageTitle.part1')} <br/>{t('homepageTitle.part2')} <br/>{t('homepageTitle.part3')}</h1>
      {/* <div className="video-link" onClick={() => setIsVideoModalOpen(true)}>
        <img src={playIcon} alt="play video" />
        <span>{t('videoLink')}</span>
      </div> */}

      <div className="grid top-images">
        <div className="grid-cell">
          <img src={messyDoodle} alt="messy doodle" />
        </div>
        <div className="grid-cell">
          <img src={meditatingDoodle} alt="meditating doodle" />
        </div>
      </div>

      {/* <div className="references">
        <p>{t('customersHeader')}</p>
        <img src={trivadisLogo} alt="trivadis logo" />
        <img src={swisscomLogo} alt="swisscom logo" />
        <img src={ruagLogo} alt="ruag logo" />
      </div> */}

      <h2 className="advantages-header medium-screens">{t('biztripsAdvantagesTitle')}</h2>
      <div className="grid advantages-grid">
        <div className="grid-cell advantages-menu">
          <h2 className="big-screens">{t('biztripsAdvantagesTitle')}</h2>
          <h3 className="small-screens">{t('biztripsAdvantagesTitle')}</h3>
          <div className="tiles">
            <div className={`tile ${activeAdvantagesTile === 1 && 'tile-selected'}`} onClick={() => setActiveAdvantagesTile(1)}>
              <img src={cloudIcon} alt="tile icon" />
              <div className="tile-texts">
                <div className="title">{t('biztripsAdvantage1Title')}</div>
                { activeAdvantagesTile === 1 && <div className="text">{t('biztripsAdvantage1Text')}</div> }
                { activeAdvantagesTile === 1 && <img src={biztripsAdvantage1} alt="biztrips features" className="small-screens small-screen-time-image"/> }
              </div>
            </div>
            <div className={`tile ${activeAdvantagesTile === 2 && 'tile-selected'}`} onClick={() => setActiveAdvantagesTile(2)}>
              <img src={eyeIcon} alt="tile icon" />
              <div className="tile-texts">
                <div className="title">{t('biztripsAdvantage2Title')}</div>
                { activeAdvantagesTile === 2 && <div className="text">{t('biztripsAdvantage2Text')}</div> }
                { activeAdvantagesTile === 2 && <img src={biztripsAdvantage2} alt="biztrips features" className="small-screens small-screen-time-image"/> }
              </div>
            </div>
            <div className={`tile ${activeAdvantagesTile === 3 && 'tile-selected'}`} onClick={() => setActiveAdvantagesTile(3)}>
              <img src={chartIcon} alt="tile icon" />
              <div className="tile-texts">
                <div className="title">{t('biztripsAdvantage3Title')}</div>
                { activeAdvantagesTile === 3 && <div className="text">{t('biztripsAdvantage3Text')}</div> }
                { activeAdvantagesTile === 3 && <img src={biztripsAdvantage3} alt="biztrips features" className="small-screens small-screen-time-image"/> }
              </div>
            </div>
            <div className={`tile ${activeAdvantagesTile === 4 && 'tile-selected'}`} onClick={() => setActiveAdvantagesTile(4)}>
              <img src={tickIcon} alt="tile icon" />
              <div className="tile-texts">
                <div className="title">{t('biztripsAdvantage4Title')}</div>
                { activeAdvantagesTile === 4 && <div className="text">{t('biztripsAdvantage4Text')}</div> }
                { activeAdvantagesTile === 4 && <img src={biztripsAdvantage4} alt="biztrips features" className="small-screens small-screen-time-image"/> }
              </div>
            </div>
          </div>
        </div>
        <div className="grid-cell advantages-images medium-and-big-screens">
          <div className="tile-image">
            { activeAdvantagesTile === 1 && <img src={biztripsAdvantage1} alt="biztrips features" /> }
            { activeAdvantagesTile === 2 && <img src={biztripsAdvantage2} alt="biztrips features" /> }
            { activeAdvantagesTile === 3 && <img src={biztripsAdvantage3} alt="biztrips features" /> }
            { activeAdvantagesTile === 4 && <img src={biztripsAdvantage4} alt="biztrips features" /> }
          </div>
        </div>
      </div>

      <div className="swing-section">
        <div className="left">
          <h2>{t('swingSectionTitle')}</h2>
          <div>{t('swingSectionText')}</div>
        </div>
        <div className="right medium-and-big-screens" />
        <img src={swingingDoodle} alt="swinging doodle" className="swing-image"/>

        <img src={wavesLeft} alt="waves left" className="waves-left big-screens" />
        <img src={wavesRight} alt="waves right" className="waves-right big-screens" />
      </div>

      <div className="grid links-grid medium-and-big-screens">
        <div className="grid-cell">
          <div className="title">{t('demoButtonTitle')}</div>
          <a href="https://outlook.office365.com/owa/calendar/Biztrips1@slynetch.onmicrosoft.com/bookings/" target="_blank" rel="noopener noreferrer"><button className="button">{t('demoButtonText')}</button></a>
        </div>
        <div className="grid-cell">
          <div className="title">{t('featuresButtonTitle')}</div>
          <Link to="/why-biztrips"><button className="button">{t('featuresButtonText')}</button></Link>
        </div>
        <div className="grid-cell">
          <div className="title">{t('pricingButtonTitle')}</div>
          <Link to="/pricing"><button className="button">{t('pricingButtonText')}</button></Link>
        </div>
      </div>

      <div className="links-section small-screens">
        <div className="link-title">{t('demoButtonTitle')}</div>
        <a href="https://outlook.office365.com/owa/calendar/Biztrips1@slynetch.onmicrosoft.com/bookings/" target="_blank" rel="noopener noreferrer"><button className="button">{t('demoButtonText')}</button></a>
        <div className="link-title">{t('pricingButtonTitle')}</div>
          <Link to="/pricing"><button className="button button-reversed">{t('pricingButtonText')}</button></Link>
        <div className="link-title">{t('featuresButtonTitle')}</div>
          <Link to="/why-biztrips"><button className="button button-reversed">{t('featuresButtonText')}</button></Link>
      </div>

      <div id="#contact"></div>

      <div className="grid contact-grid">
        <div className="grid-cell contact-form-area" id="contact">
          <p>{t('contactFormTitle')}</p>
          <div className="input-wrapper">
            <input className="text-input email-input" type="text" placeholder={t('emailInputPlaceholder')} value={email} onChange={e => setEmail(e.target.value)}/>
          </div>
          <div className="input-wrapper">
            <input className="text-input name-input" type="text" placeholder={t('nameInputPlaceholder')} value={name} onChange={e => setName(e.target.value)}/>
          </div>
          <div className="input-wrapper">
            <textarea className="textarea-input" placeholder={t('messageTextareaPlaceholder')} value={message} onChange={e => setMessage(e.target.value)} />
          </div>
          <button className={sendButtonClasses} onClick={handleSendMessage}>{sendButtonText}</button>

          <p className="captcha-statement">
            This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
          </p>

          {
            contactFormError && <div className="error-message">{contactFormError}</div>
          }
        </div>
        <div className="grid-cell contact-form-image">
          <img src={lovingDoodle} alt="contact" />
        </div>
      </div>

      <div className="grid roadmap-grid">
        <div className="grid-cell roadmap-image">
          <img src={coffeeDoddle} alt="coffee" />
        </div>
        <div className="grid-cell roadmap-area">
          <h3>{t('coffeeText1')}</h3>
          <p>{t('coffeeText2')}</p>
          <p>{t('coffeeText3')}</p>
          <Link to="/roadmap"><button className="button">{t('roadmapButtonText')}</button></Link>
        </div>
      </div>

      {
        isVideoModalOpen && <div className="video-modal" onClick={() => setIsVideoModalOpen(false)}>
          <div className="video-wrapper">
            <iframe src="https://www.youtube.com/embed/DmD5lTRYNQ4" 
              title="biztrips-video" 
              frameBorder="0" 
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
              allowfullscreen>
            </iframe>
            <div className="close-icon" onClick={() => setIsVideoModalOpen(false)}>
              <img src={closeIconWhite} alt="close modal" />
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default HomePage