import React from 'react'
import './PricingPage.scss'
import { HashLink as Link } from 'react-router-hash-link'
import iceCreamDoodle from '../../../assets/ice-cream-doodle.svg'
import selfieDoodle from '../../../assets/selfie-doodle.svg'
import { useTranslation } from 'react-i18next'
import { Helmet } from "react-helmet"


function PricingPage() {
  const { t } = useTranslation()

  return (
    <div className="pricing-page">

      <Helmet>
        <title>{t('metaData.pricingTitle')}</title>
        <meta name="description" content={t('metaData.pricingDescription')} />
      </Helmet>

      <div className="packages">
        <div className="package">
          <div>
            <div className="package-image-wrapper">
              <img src={iceCreamDoodle} alt="package doodle" className="doodle-smallest" />
            </div>
            <div className="title">{t('package1.title')}</div>
            <div className="subtitle">{t('package1.subtitle')}</div>
            <div className="price">{t('package1.price') || <>&nbsp;</>}</div>
            <div className="subtitle">{t('package1.priceLabel')}</div>
            <ul>
              <li><span className="bullet" /><span>{t('package1.feature1')}</span></li>
              <li><span className="bullet" /><span>{t('package1.feature2')}</span></li>
              <li><span className="bullet" /><span>{t('package1.feature3')}</span></li>
            </ul>
          </div>
          <a href="https://share.hsforms.com/1Rq4iXs3qSMKJj_PJiKUQkA4dt2x" target="_blank" rel="noopener noreferrer" className="button">{t('package1.button')}</a>
        </div>

        <div className="package">
          <div>
            <div className="package-image-wrapper">
              <img src={iceCreamDoodle} alt="package doodle"  className="doodle-smaller" />
            </div>
            <div className="title">{t('package2.title')}</div>
            <div className="subtitle">{t('package2.subtitle')}</div>
            <div className="price">{t('package2.price') || <>&nbsp;</>}</div>
            <div className="subtitle">{t('package2.priceLabel')}</div>
            <ul>
              <li><span className="bullet" /><span>{t('package2.feature1')}</span></li>
              <li><span className="bullet" /><span>{t('package2.feature2')}</span></li>
              <li><span className="bullet" /><span>{t('package2.feature3')}</span></li>
            </ul>
          </div>
          <a href="https://share.hsforms.com/1Rq4iXs3qSMKJj_PJiKUQkA4dt2x" target="_blank" rel="noopener noreferrer" className="button">{t('package2.button')}</a>
        </div>

        <div className="package">
          <div>
            <div className="package-image-wrapper">
              <img src={iceCreamDoodle} alt="package doodle" />
            </div>
            <div className="title">{t('package3.title')}</div>
            <div className="subtitle">{t('package3.subtitle')}</div>
            <div className="price">{t('package3.price') || <>&nbsp;</>}</div>
            <div className="subtitle">{t('package3.priceLabel')}</div>
            <ul>
              <li><span className="bullet" /><span>{t('package3.feature1')}</span></li>
              <li><span className="bullet" /><span>{t('package3.feature2')}</span></li>
              <li><span className="bullet" /><span>{t('package3.feature3')}</span></li>
            </ul>
          </div>
          <a href="https://share.hsforms.com/1Rq4iXs3qSMKJj_PJiKUQkA4dt2x" target="_blank" rel="noopener noreferrer" className="button">{t('package3.button')}</a>
        </div>

        <div className="package">
          <div>
            <div className="package-image-wrapper">
              <img src={selfieDoodle} alt="package doodle" />
            </div>
            <div className="title">{t('package4.title')}</div>
            <div className="subtitle">{t('package4.subtitle')}</div>
            <div className="price">{t('package4.price') || <>&nbsp;</>}</div>
            <div className="subtitle">{t('package4.priceLabel')}</div>
            <ul>
              <li><span className="bullet" /><span>{t('package4.feature1')}</span></li>
              <li><span className="bullet" /><span>{t('package4.feature2')}</span></li>
              <li><span className="bullet" /><span>{t('package4.feature3')}</span></li>
            </ul>
          </div>
          <a href="https://share.hsforms.com/1mEtgYnExTyC2wasrxQIWZw4dt2x" target="_blank" rel="noopener noreferrer" className="button">{t('package4.button')}</a>
        </div>
      </div>

      
      <div className="consultant-section">
        <h1>{t('consultantTitle')}</h1>
        <p>
          {t('consultantSubtitlePart1')}<br/>
          {t('consultantSubtitlePart2')}
        </p>
        <a href="https://share.hsforms.com/1mEtgYnExTyC2wasrxQIWZw4dt2x" target="_blank" rel="noopener noreferrer" className="button">{t('contactUsButtonText')}</a>
      </div>
    </div>
  )
}

export default PricingPage