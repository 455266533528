import React, { useState, useEffect } from 'react'
import './CookiesBanner.scss'
import { HashLink as Link } from 'react-router-hash-link'
import { getCookie, initializeGoogleAnalytics } from '../../utils'
import { useTranslation } from 'react-i18next'


function CookiesBanner() {
  const [acceptedCookies, setAcceptedCookies] = useState(null)
  const [functionalCookiesAccepted, setFunctionalCookiesAccepted] = useState(true)
  const [analyticalCookiesAccepted, setAnalyticalCookiesAccepted] = useState(true)

  const { t } = useTranslation()


  useEffect(() => {
    setAcceptedCookies(getCookie('acceptedCookies'))
  }, [])


  const acceptCookies = () => {
    setAcceptedCookies('all')
    document.cookie = `acceptedCookies=all; path=/`
    initializeGoogleAnalytics()
  }


  const acceptCookiesWithPreferences = () => {
    setAcceptedCookies(analyticalCookiesAccepted ? 'all' : 'functional')
    document.cookie = `acceptedCookies=${analyticalCookiesAccepted ? 'all' : 'functional'}; path=/`
    analyticalCookiesAccepted && initializeGoogleAnalytics()
  }


  return !acceptedCookies && (
    <div className="cookies-banner">
      <div className="cookies-banner-inner">
        <div className="left">
          <span>{t('cookieCosent.text1')} </span>
          <span><Link to="/about-us#gdpr">{t('cookieCosent.text2')}</Link> </span>
          <span>{t('cookieCosent.text3')} </span>
          <input type="checkbox" id="functional-cookies" checked={functionalCookiesAccepted} disabled />
          <label htmlFor="functional-cookies" >{t('cookieCosent.checkbox1')}</label>
          <span> {t('cookieCosent.text4')} </span>
          <input type="checkbox" id="analytical-cookies" checked={analyticalCookiesAccepted} onChange={() => setAnalyticalCookiesAccepted(!analyticalCookiesAccepted)} />
          <label htmlFor="analytical-cookies" >{t('cookieCosent.checkbox2')}</label>
          <span> {t('cookieCosent.text5')} </span>
        </div>
        <div className="right">
          <button className="button" onClick={acceptCookies}>{t('cookieCosent.button1')}</button>
          <button className="button button-reversed" onClick={acceptCookiesWithPreferences}>{t('cookieCosent.button2')}</button>
        </div>
      </div>
    </div>
  )
}

export default CookiesBanner