import React, { useState, useEffect } from 'react'
import './Footer.scss'
import { getCookie } from '../../utils'
import { useTranslation } from 'react-i18next'


function Footer() {
  const [language, setLanguage] = useState()
  const { t, i18n } = useTranslation()


  useEffect(() => {
    const languageCode = getCookie('language')
    setLanguage(languageCode || 'en')
    i18n.changeLanguage(languageCode)
  }, [])


  const changeLanguage = languageCode => {
    setLanguage(languageCode)
    i18n.changeLanguage(languageCode)
    document.cookie = `language=${languageCode}; path=/`
  }


  return (
    <div className="footer">
      <div className="footer-inner">
        <div className="left"><a href="https://www.slynet.ch/" target="_blank" rel="noopener noreferrer">{t('footerText1')}</a>{t('footerText2')}</div>
        <div className="right">
          <span className={`language ${language === 'de' && 'active-language'}`} onClick={() => changeLanguage('de')}>{t('germanLanguageButtonText')}</span>
          <span> | </span>
          <span className={`language ${language === 'en' && 'active-language'}`} onClick={() => changeLanguage('en')}>{t('englishLanguageButtonText')}</span>
        </div>
      </div>
    </div>
  )
}

export default Footer