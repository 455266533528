import React, { useState, useEffect } from 'react'
import './WhyBiztripsPage.scss'
import featuresDesktopEN from '../../../assets/features-desktop.svg'
import featuresDesktopDE from '../../../assets/features-desktop-de.svg'
import featuresDesktopMedium from '../../../assets/features-desktop-medium.svg'
import featuresMobileEN from '../../../assets/features-mobile.svg'
import featuresMobileDE from '../../../assets/features-mobile-de.svg'
import featuresMobileSmallEN from '../../../assets/features-mobile-small.svg'
import featuresMobileSmallDE from '../../../assets/features-mobile-small-de.svg'
import dagmarPhoto from '../../../assets/dagmar-photo.svg'
import smileIcon from '../../../assets/smile-icon.svg'
import clipboardIcon from '../../../assets/clipboard-icon.svg'
import zapIcon from '../../../assets/zap-icon.svg'
import gitIcon from '../../../assets/git-icon.svg'
import languagesIcon from '../../../assets/languages-icon.svg'
import smartphoneIcon from '../../../assets/smartphone-icon.svg'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet"


function WhyBiztripsPage() {
  const [language, setLanguage] = useState('en')
  const { t, i18n } = useTranslation()


  useEffect(() => {
    setLanguage(i18n.language || 'en')
  }, [i18n.language])


  const renderImage = (imageEN, imageDE) => {
    if (language === 'en') {
      return imageEN
    } else {
      return imageDE
    }
  }


  return (
    <div className="why-biztrips-page">

    <Helmet>
      <title>{t('metaData.whyBiztripsTitle')}</title>
      <meta name="description" content={t('metaData.whyBiztripsDescription')} />
    </Helmet>

      <img src={renderImage(featuresDesktopEN, featuresDesktopDE)} alt="features desktop" className="top-image big-screens" />
      <img src={featuresDesktopMedium} alt="features desktop" className="top-image medium-screens" />
      <div className="top-image-mobile-wrapper">
        <img src={renderImage(featuresMobileSmallEN, featuresMobileSmallDE)} alt="features mobile" className="top-image small-screens" />
      </div>

      <div className="features-grid medium-screens">
        <div className="grid-cell">
          <p><strong>{t('feature1Title')}</strong></p>
          <p>{t('feature1Text')}</p>
        </div>
        <div className="grid-cell">
          <p><strong>{t('feature2Title')}</strong></p>
          <p>{t('feature2Text')}</p>
        </div>
        <div className="grid-cell">
          <p><strong>{t('feature3Title')}</strong></p>
          <p>{t('feature3Text')}</p></div>
        <div className="grid-cell">
          <p><strong>{t('feature4Title')}</strong></p>
          <p>{t('feature4Text')}</p></div>
        <div className="grid-cell">
          <p><strong>{t('feature5Title')}</strong></p>
          <p>{t('feature5Text')}</p></div>
        <div className="grid-cell">
          <p><strong>{t('feature6Title')}</strong></p>
          <p>{t('feature6Text')}</p></div>
      </div>

      <div className="features-2-grid">
        <div className="features-2-grid-item">
          <div className="grid-item-title-line">
            <img src={smileIcon} alt="" className="grid-item-title-icon" />
            <div className="grid-item-title-text">{t('feature2-1Title')}</div>
          </div>
          <div className="grid-item-text">{t('feature2-1Text')}</div>
        </div>
        
        <div className="features-2-grid-item">
          <div className="grid-item-title-line">
            <img src={clipboardIcon} alt="" className="grid-item-title-icon" />
            <div className="grid-item-title-text">{t('feature2-2Title')}</div>
          </div>
          <div className="grid-item-text">{t('feature2-2Text')}</div>
        </div>
        
        <div className="features-2-grid-item">
          <div className="grid-item-title-line">
            <img src={zapIcon} alt="" className="grid-item-title-icon" />
            <div className="grid-item-title-text">{t('feature2-3Title')}</div>
          </div>
          <div className="grid-item-text">{t('feature2-3Text')}</div>
        </div>
        
        <div className="features-2-grid-item">
          <div className="grid-item-title-line">
            <img src={gitIcon} alt="" className="grid-item-title-icon" />
            <div className="grid-item-title-text">{t('feature2-4Title')}</div>
          </div>
          <div className="grid-item-text">{t('feature2-4Text')}</div>
        </div>
        
        <div className="features-2-grid-item">
          <div className="grid-item-title-line">
            <img src={languagesIcon} alt="" className="grid-item-title-icon" />
            <div className="grid-item-title-text">{t('feature2-5Title')}</div>
          </div>
          <div className="grid-item-text">{t('feature2-5Text')}</div>
        </div>
        
        <div className="features-2-grid-item">
          <div className="grid-item-title-line">
            <img src={smartphoneIcon} alt="" className="grid-item-title-icon" />
            <div className="grid-item-title-text">{t('feature2-6Title')}</div>
          </div>
          <div className="grid-item-text">{t('feature2-6Text')}</div>
        </div>
      </div>

      <div className="buttons-line">
        <a href="https://outlook.office365.com/owa/calendar/Biztrips1@slynetch.onmicrosoft.com/bookings/" target="_blank" rel="noopener noreferrer"><button className="button">{t('scheduleDemoButtonText')}</button></a>
        <Link to="/pricing" className="button button-secondary">{t('checkPricingButton')}</Link>
      </div>

      <div className="demo-section">
        <div className="left">
          <div className="quote-section">
            <div className="quote-left-area">
              <p className="quote">{t('whyBiztripsQuote')}</p>
              <p className="author">{t('whyBiztripsQuoteAuthor')}</p>
            </div>
            <div className="quote-right-area">
              <img src={dagmarPhoto} alt="author photo" />
            </div>
          </div>
        </div>
        <div className="right" className="big-screens">
          <img src={renderImage(featuresMobileEN, featuresMobileDE)} alt="features mobile" />
        </div>
      </div>
    </div>
  )
}

export default WhyBiztripsPage