import React, { useEffect } from 'react'
import './SignInPage.scss'
import LoginBox from '../../LoginBox/LoginBox'
import { useTranslation } from 'react-i18next'
import { Helmet } from "react-helmet"


function SignInPage() {
  const { t } = useTranslation()

  useEffect(() => {
    const singInPage = document.querySelector('.sign-in-page')
    const menu = document.querySelector('.menu')
    const footer = document.querySelector('.footer')
    singInPage.style.minHeight = `calc(100vh - ${menu.clientHeight}px - ${footer.clientHeight}px)`
  }, [])
  

  return (
    <div className="sign-in-page">

      <Helmet>
        <title>{t('metaData.signUpTitle')}</title>
        <meta name="description" content={t('metaData.signUpDescription')} />
      </Helmet>

      <LoginBox signUpPage={true} />
    </div>
  )
}

export default SignInPage