import React from 'react'
import './App.css'
import './GlobalStyles.scss'
import { BrowserRouter as Router } from "react-router-dom"
import MainComponent from './components/MainComponent/MainComponent'
import { ScrollToTop } from './utils'


function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <MainComponent />
      </Router>
    </div>
  )
}

export default App
