import React from 'react'
import './MainComponent.scss'
import { Switch, Route } from 'react-router-dom'
import Menu from '../Menu/Menu'
import HomePage from '../pages/HomePage/HomePage'
import WhyBiztripsPage from '../pages/WhyBiztripsPage/WhyBiztripsPage'
import PricingPage from '../pages/PricingPage/PricingPage'
import AboutUsPage from '../pages/AboutUsPage/AboutUsPage'
import RoadmapPage from '../pages/RoadmapPage/RoadmapPage'
import SingInPage from '../pages/SignInPage/SignInPage'
import Footer from '../Footer/Footer'
import CookiesBanner from '../CookiesBanner/CookiesBanner'
import { initializeGoogleAnalytics } from '../../utils'
import { RECAPTCHA_KEY } from '../../config'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'


initializeGoogleAnalytics()


function MainComponent() {

  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY} >
      <div className="main-component">
        <div className="content-wrapper">
          <Menu />
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/why-biztrips" component={WhyBiztripsPage} />
            <Route exact path="/pricing" component={PricingPage} />
            <Route exact path="/about-us" component={AboutUsPage} />
            <Route exact path="/roadmap" component={RoadmapPage} />
            <Route exact path="/sign-in" component={SingInPage} />
          </Switch>
        </div>
        <Footer />
        <CookiesBanner />
      </div>
    </GoogleReCaptchaProvider>
  )
}

export default MainComponent
