import React, { useEffect } from 'react'
import './AboutUsPage.scss'
import dancingDoodle from '../../../assets/dancing-doodle.svg'
import gdprDocument from '../../../assets/BiztripsGDPR.pdf'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import dagmarPhoto from '../../../assets/dagmar-photo.svg'
import michalPhoto from '../../../assets/michal.jpg'
import pascalPhoto from '../../../assets/pascal-photo.jpg'
import salomonPhoto from '../../../assets/salomon-photo.jpg'
import tanjaPhoto from '../../../assets/tanja-photo.png'
import shape1 from '../../../assets/team-member-1-shape.svg'
import shape2 from '../../../assets/team-member-2-shape.svg'
import shape3 from '../../../assets/team-member-3-shape.svg'
import shape4 from '../../../assets/team-member-4-shape.svg'
import { Helmet } from "react-helmet"


function AboutUsPage(props) {
  const { t, i18n } = useTranslation()

  useEffect(() => {
    const anchor = props.location.hash
    if (anchor) {
      const domElement = document.querySelector(anchor)
      if (domElement) {
        setTimeout(() => {
          domElement.scrollIntoView()
        }, 300)
      }
    }
  }, [])

  console.log(i18n.language)

  return (
    <div className="about-us-page">

      <Helmet>
        <title>{t('metaData.aboutUsTitle')}</title>
        <meta name="description" content={t('metaData.aboutUsDescription')} />
      </Helmet>

      <h1>{t('aboutUsTitle')}</h1>

      <h2>{t('aboutUsSubtitle1')}</h2>
      <p>{t('aboutUsText1')}</p>

      <div className="team-members-grid">
        <div className="team-member team-member-1">
          <img src={pascalPhoto} alt="team member" />
          <div className="team-member-label">Pascal, Founder</div>
          <img className="shape" src={shape1} alt="shape" />
        </div>
        <div className="team-member team-member-2">
          <img src={salomonPhoto} alt="team member" />
          <div className="team-member-label">Salomon, Project lead</div>
          <img className="shape" src={shape2} alt="shape" />
        </div>
        <div className="team-member team-member-3">
          <img src={tanjaPhoto} alt="team member" />
          <div className="team-member-label">Tanja, HR</div>
          <img className="shape" src={shape3} alt="shape" />
        </div>
        <div className="team-member team-member-4">
          <img src={michalPhoto} alt="team member" />
          <div className="team-member-label">Michał, Developer</div>
          <img className="shape" src={shape4} alt="shape" />
        </div>
      </div>

      <div className="partners-section-title">{t('aboutUsSubtitle4')}</div>
      <p>{t('aboutUsText4')}</p>
      <div className="team-member">
        <img src={dagmarPhoto} alt="team member" />
        <div className="team-member-label">Dagmar, CEO</div>
        <a href="https://www.internationalhrs.com/" target="_blank" rel="noopener noreferrer">internationalhrs.com</a>
      </div>

      <Link to="/#contact"><button className="button contact-us-button">{t('aboutUsContactUsButtonText')}</button></Link>

      <h2>{t('aboutUsSubtitle2')}</h2>
      <p>{t('aboutUsText2')}</p>
      <a href="https://www.colocation-datacenter.com/locations/overview.html" target="_blank" rel="noopener noreferrer"><button className="button">{t('datacenterButtonText')}</button></a>

      <div id="gdpr"></div>
      <h2>{t('aboutUsSubtitle3')}</h2>
      <p>{t('aboutUsText3')}</p>
      <a href={gdprDocument}><button className="button">{t('gdprButtonText')}</button></a>
      
      <img src={dancingDoodle} alt="doodle" className="doodle-image big-screens" />
    </div>
  )
}

export default AboutUsPage
