import React, { useState, useEffect } from 'react'
import './RoadmapPage.scss'
import RoadMapBigSVG from './RoadMapBigSVG'
import RoadMapMediumSVG from './RoadMapMediumSVG'
import RoadMapSmallSVG from './RoadMapSmallSVG'
import { useTranslation } from 'react-i18next'
import { Helmet } from "react-helmet"


function RoadmapPage() {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)
  const { t } = useTranslation()
  let initialCarOffset = null


  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }
  }, [])


  useEffect(() => {
    setTimeout(() => {
      const car = document.querySelector('.car')
      if (!car) return
      initialCarOffset = car.getBoundingClientRect().y
    }, 0)
  }, [])


  const handleScroll = () => {
    animateCar()
    animateClouds()
    animateTrees()
  }


  const animateCar = () => {
    const car = document.querySelector('.car')
    if (!car) return
    if (window.scrollY > initialCarOffset - 20) {
      const roadmapImage = document.querySelector('.roadmap-wrapper')
      const imageMaxWidth = document.defaultView.getComputedStyle(roadmapImage).getPropertyValue('max-width')
      const imageRealWidth = document.defaultView.getComputedStyle(roadmapImage).getPropertyValue('width')
      const imageWidthRatio = parseFloat(imageRealWidth) / parseFloat(imageMaxWidth)
      car.style.transform = `translateY(${window.scrollY / imageWidthRatio - initialCarOffset / imageWidthRatio + 20}px)`
    }
  }


  const animateClouds = () => {
    const cloudLeft = document.querySelector('.cloud-left')
    if (!cloudLeft) return
    if (cloudLeft.getBoundingClientRect().y < 300) {
      cloudLeft.style.transform = `translateX(${(cloudLeft.getBoundingClientRect().y - 300) * 0.6}px)`
    }

    const cloudRight = document.querySelector('.cloud-right')
    if (!cloudRight) return
    if (cloudRight.getBoundingClientRect().y < 330) {
      cloudRight.style.transform = `translateX(${(-cloudLeft.getBoundingClientRect().y + 330) * 0.3}px)`
    }
  }


  const animateTrees = () => {
    const trees = document.querySelectorAll('.tree')
    Array.from(trees).forEach(tree => {
      const treePosition = tree.getBoundingClientRect().y
      if (treePosition < 400) {
        tree.style.fill = '#090'
      } else {
        tree.style.fill = '#F6872D'
      }
    })
  }


  const handleResize = () => {
    setInnerWidth(window.innerWidth)
  }


  return (
    <div className="roadmap-page">

    <Helmet>
      <title>{t('metaData.roadmapTitle')}</title>
      <meta name="description" content={t('metaData.roadmapDescription')} />
    </Helmet>
      
      { innerWidth > 1200 && <div className="roadmap-wrapper roadmap-big">
        <RoadMapBigSVG />
      </div> }
      
      { innerWidth > 768 && window.innerWidth <= 1200 && <div className="roadmap-wrapper roadmap-medium">
        <RoadMapMediumSVG />
      </div> }
      
      { innerWidth <= 768 && <div className="roadmap-wrapper roadmap-small">
        <RoadMapSmallSVG />
      </div> }

    </div>
  )
}

export default RoadmapPage