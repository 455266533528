import React, { useState } from 'react'
import './Menu.scss'
import { Link, withRouter } from "react-router-dom"
import logo from '../../assets/logo.svg'
import hamburgerIcon from '../../assets/hamburger-icon.svg'
import closeIcon from '../../assets/close-icon.svg'
import { useTranslation } from 'react-i18next'
import LoginBox from '../LoginBox/LoginBox'


function Menu(props) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const location = props.location.pathname.toString()
  const { t } = useTranslation()


  return (
    <header className="menu">
      <Link to="/">
        <img src={logo} alt="biztrips" title="biztrips" />
      </Link>
      <Link to="/why-biztrips" className={`medium-and-big-screens ${location === '/why-biztrips' && 'bold'}`}>{t('navbar.whyBiztrips')}</Link>
      <Link to="/pricing" className={`medium-and-big-screens ${location === '/pricing' && 'bold'}`}>{t('navbar.pricing')}</Link>
      <Link to="/about-us" className={`medium-and-big-screens ${location === '/about-us' && 'bold'}`}>{t('navbar.aboutUs')}</Link>
      <Link to="/roadmap" className={`medium-and-big-screens ${location === '/roadmap' && 'bold'}`}>{t('navbar.roadmap')}</Link>
      <Link to="/sign-in" className="small-and-medium-screens with-background">{t('navbar.signIn')}</Link>
      <LoginBox />
      
      <div className="hamburger-icon-wrapper small-screens" onClick={() => setIsMobileMenuOpen(true)}>
        <img src={hamburgerIcon} alt="menu icon" /> 
      </div>

      {
        isMobileMenuOpen && <div className="mobile-menu">
          <header>
            <Link to="/" onClick={() => setIsMobileMenuOpen(false)}>
              <img src={logo} alt="biztrips" title="biztrips" />
            </Link>
            <img src={closeIcon} alt="close" className="close-icon" onClick={() => setIsMobileMenuOpen(false)} />
          </header>
          <div className="links">
            <Link to="/" className={location === '/' && `color-primary`} onClick={() => setIsMobileMenuOpen(false)}>{t('navbar.home')}</Link>
            <Link to="/why-biztrips" className={location === '/why-biztrips' && `color-primary`} onClick={() => setIsMobileMenuOpen(false)}>{t('navbar.whyBiztrips')}</Link>
            <Link to="/pricing" className={location === '/pricing' && `color-primary`} onClick={() => setIsMobileMenuOpen(false)}>{t('navbar.pricing')}</Link>
            <Link to="/about-us" className={location === '/about-us' && `color-primary`} onClick={() => setIsMobileMenuOpen(false)}>{t('navbar.aboutUs')}</Link>
            <Link to="/roadmap" className={location === '/roadmap' && `color-primary`} onClick={() => setIsMobileMenuOpen(false)}>{t('navbar.roadmap')}</Link>
          </div>
        </div>
      }
    </header>
  )
}

export default withRouter(Menu)
